var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-partition-table',{attrs:{"value":_vm.selectPartiton,"data":_vm.data.Divisions,"labels":_vm.labels,"type":3,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: [
              this.provideUnits.rub.text,
              this.provideUnits.unit.text,
            ].join(' | '),
          },
        ]},on:{"input":(event) => {
            if (!_vm.loading) _vm.selectPartiton = event;
          }}}):_vm._e()],1),_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-employee-table',{attrs:{"value":_vm.selectEmployee,"selectPartiton":_vm.selectPartiton,"data":_vm.data.Divisions,"labels":_vm.labels,"type":3,"items":_vm.data.Employees,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: [
              this.provideUnits.rub.text,
              this.provideUnits.unit.text,
            ].join(' | '),
          },
        ]},on:{"input":(event) => {
            if (!_vm.loading) _vm.selectEmployee = event;
          }}}):_vm._e()],1)],1),(_vm.selectEmployee)?_c('div',{staticClass:"mt-5",staticStyle:{"padding":"15px 0 12px"}},[_c('div',{staticClass:"d-flex justify-space-between pl-4"},[_c('div',{staticClass:"d-flex mb-3",staticStyle:{"height":"40px"}},[_c('div',[_c('div',{staticStyle:{"font-weight":"bold","line-height":"1.2"}},[_vm._v(" ДЕТАЛЬНАЯ ИНФОРМАЦИЯ ПО СОТРУДНИКУ ")]),_c('div',{staticClass:"caption d-flex",staticStyle:{"line-height":"1.4"}},[_c('div',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.selectEmployee.Name))])])])])]),(_vm.loading)?_c('div',{staticStyle:{"position":"relative","height":"300px"}},[_c('wrapper-loader')],1):_vm._e(),(_vm.details && !_vm.loading)?_c('v-row',[_c('v-col',[_c('tfoms-bar',{attrs:{"title":"ОБЪЕМЫ","data":_vm.getValues(_vm.details.VolumeValues),"icon":"mdi-currency-rub","labels":_vm.labelsFull,"sub-title":_vm.subTitlePeriod}}),_c('tfoms-finance',{staticClass:"mt-5",attrs:{"title":"КОЛИЧЕСТВО САНКЦИЙ","data":_vm.details.SanctionCountValues.map((e) => [
              e.NonPaymentVolumeValue,
              e.FineVolumeValue,
            ]),"labels":_vm.labelsFull,"values":[
            {
              value: _vm.details.SanctionVolumeCount,
              label: _vm.provideUnits.unit.text,
            },
          ],"sub-title":_vm.subTitlePeriod}})],1),_c('v-col',[_c('tfoms-bar',{attrs:{"title":"ДОХОДЫ","data":_vm.getValues(
              _vm.details.FinanceValues,
              'FinancialPlanValue',
              'FinancialValue'
            ),"icon":"mdi-currency-rub","factColor":"#0CA85D","labels":_vm.labelsFull,"values":[
            {
              value: _vm.details.FinanceTotalValue,
              label: _vm.provideUnits.rub.text,
            },
          ],"sub-title":_vm.subTitlePeriod}}),_c('tfoms-finance',{staticClass:"mt-5",attrs:{"title":"СУММА САНКЦИЙ","data":_vm.details.SanctionValues.map((e) => [e.SanctionValue, e.FineValue]),"labels":_vm.labelsFull,"values":[
            {
              value: _vm.details.SanctionTotalValue,
              label: _vm.provideUnits.rub.text,
            },
          ],"sub-title":_vm.subTitlePeriod}})],1)],1):_vm._e(),(_vm.details && !_vm.loading)?_c('tfoms-expertise-table',{staticClass:"mt-5",attrs:{"data":_vm.details.ExpertiseResults,"labels":_vm.labelsFull,"sub-title":_vm.subTitlePeriod}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }